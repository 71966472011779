<template>
  <MDBCard class="m-3 " :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader v-if="(Action == 'insert')">{{ $t("MEMBER-TAG.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(Action == 'update')">{{ $t("MEMBER-TAG.EDIT-ITEM") }}</MDBCardHeader>
          <MDBCardText class="mt-2">
            <MDBRow class="mb-2">
              <MDBCol md="6">
                <MDBInput :label="$t('COMMON.NAME')" type="text" v-model="Form.name.value"/>
                <span class="text-danger">{{ nameError }}</span>
              </MDBCol>
            </MDBRow>
          </MDBCardText>
          <MDBBtn v-if="(Action == 'insert')" color="primary" type="submit">
            {{ $t("COMMON.INSERT") }}
          </MDBBtn>
          <MDBBtn v-else-if="(Action == 'update')" color="primary" type="submit">
            {{ $t("COMMON.UPDATE") }}
          </MDBBtn>
          <MDBBtn type="button" @click="CloseModel()">{{ $t("COMMON.CANCEL") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBInput, MDBRow, MDBCard, MDBCardHeader, MDBCardBody, MDBCardText, MDBBtn, MDBCol } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';

export default {
  name: "TagsForm",
  components: {
    MDBInput,
    MDBRow,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardText,
    MDBBtn,
    MDBCol,
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    model: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.model,
      set: val => emit("update:model", val)
    });

    const Action = computed({
      get: () => props.action,
      set: val => emit("update:action", val)
    });

    const TagUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const GetTable = inject("GetTable");

    const i18n = useI18n();

    const basicSchema = {
      name: yup.string().required().label(i18n.t("COMMON.NAME")),
    };

    let ExtendSchema = {};
    if(Action.value == "insert") {
      ExtendSchema = {
      };  
    }else{
      ExtendSchema = {
      };
    }
    
    const MemberSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm } = useForm({
      validationSchema: MemberSchema
    });

    const { value: name, errorMessage: nameError } = useField('name');

    let Submit;

    if(Action.value == "insert") {
      Submit = handleSubmit(values => {
        ApiService.post("/Admin/v1/Tags", values).then(response => {
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            GetTable();
          }
        });
      });
    }else{
      Submit = handleSubmit(values => {
        ApiService.update("/Admin/v1/Tags",props.uuid ,values).then(response => {
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            TagUUid.value = "";
            GetTable();
          }
        });
      });
    }

    return {
      Model,
      Action,
      Form: {
        name,
      },
      nameError,
      Submit,
      TagUUid,
      resetForm
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    }
  },
  methods: {
    GetDetail() {
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Tags", this.uuid).then(response => {
            if (response.status == 200) {
              this.resetForm();
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    },
    CloseModel() {
      this.TagUUid = "";
      this.Model = false;
    }
  }
};
</script>