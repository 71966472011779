<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog)}">
    <MDBCardBody>
      <MDBContainer fluid class="mt-3">
        <MDBRow>
          <MDBCol class="mb-2 text-end">
            <!-- <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn> -->
            <MDBBtn color="info" @click="add_dialog = true">{{ $t("MEMBER-TAG.ADD-ITEM") }}</MDBBtn>  
          </MDBCol>
        </MDBRow>
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <!-- 時間轉換 -->
          <template v-slot:created_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
          <template v-slot:status="{ item }">
            {{ $t("MEMBER.STATUS_" + item.status) }}
          </template>
          <template v-slot:members="{ item }">
            {{ item.members.length }}
          </template>
          <!-- 操作template -->
          <!-- <template v-slot:uuid="{ item }">
            <a @click="GetDetail(item.uuid)" data-bs-toggle="tooltip" :title="$t('MEMBER-TAG.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
            </a>
            <a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('MEMBER-TAG.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
            </a>
          </template> -->
          <template v-slot:edit="{ item }">
            <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
          <template v-slot:uuid="{ item }">
            <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
        </DataTable>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
	<TagsForm action="insert" v-model:model="add_dialog"></TagsForm>
	<TagsForm action="update" v-model:model="edit_dialog" v-model:uuid="EditUUid"></TagsForm>
</template>

<script>
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import TagsForm from '@/components/TagsForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
export default {
  name: "MemberTag",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		TagsForm,
		MDBBtn,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody
  },
  data() {
    return {
      add_dialog: false,
      edit_dialog: false,
      loading_dailog: false,
      loading: true,
      EditUUid: "",
      DeleteUUid: "",
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["created_at"],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
      },
      headers: [
        {
          text: this.$i18n.t("COMMON.SEQUENCE"),
          align: "start",
          sortable: false,
          value: "no",width:"50px",class: "text-center"
        },
        { text: this.$i18n.t("COMMON.NAME"), value: "name" },
        { text: this.$i18n.t("COMMON.MEMBERTOTAL"), value: "members" },
        { text: this.$i18n.t("COMMON.CREATE_AT"), value: "created_at" },
        { text: this.$i18n.t("COMMON.EDIT"), value: "edit", sortable: false ,width:"50px",class: "text-center"},
        { text: this.$i18n.t("COMMON.DELETE"), value: "uuid", sortable: false ,width:"50px",class: "text-center"}
      ],
      desserts: [],
      total: 0,
      Common: CommonService
    };
  },
  setup() {
		return {};
  },
  mounted() {
    this.GetTable();
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    }
  },
  provide () {
    return {
      GetTable: this.GetTable
    }
  },
  methods: {
		GetTable() {
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query(
          "/Admin/v1/Tags",
          {
            params: CommonService.SortHandleObject(this.options)
          }
        ).then(response => {
          if (response.status == 200) {
            this.desserts = CommonService.DataNoHandle(
              response.data.data.data,
              this.options.page,
              this.options.itemsPerPage
            );
            this.total = response.data.data.total;
            resolve(response);
          }
          this.loading = false;
        });
      });
    },
    DeleteAction(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/Tags/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                this.GetTable();
              }
            }
          );
        }
      });
    }
  }
};
</script>